function initGoogleMaps() {
  if (!window.jQuery) {
    // if init is triggered before jQuery is loaded, retry later
    return setTimeout(initGoogleMaps, 100);
  } else if (document.readyState !== 'complete') {
    // if jQuery is loaded but document not ready, attach to event
    return $(initGoogleMaps);
  } else if (!window.google || !window.google.maps) {
    // google maps api failed to load
    throw new Error('Google Maps JS API seems to be missing, but is required for initialization!');
  }

  function offsetCenter(map, offsetx, offsety) {
    var scale = Math.pow(2, map.getZoom());
    var currentCenter = map.getCenter();
    var mapProjection = map.getProjection();

    if (mapProjection === undefined) {
      return google.maps.event.addListenerOnce(map, 'projection_changed', function (event) {
        offsetCenter(map, offsetx, offsety);
      });
    }

    var worldCoordinateCenter = mapProjection.fromLatLngToPoint(currentCenter);
    var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);
    var worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x, 
      worldCoordinateCenter.y + pixelOffset.y
    );

    map.setCenter(mapProjection.fromPointToLatLng(worldCoordinateNewCenter));
  }
  
  $('.google-map').each(function (i, e) {
    var canvas = $(this).children('.canvas').get(0);
    var map = new google.maps.Map(canvas, {
      center: {
        lat: $(this).data('center-lat'),
        lng: $(this).data('center-lng'),
      },
      backgroundColor: (isset($(this).data('color')) ? $(this).data('color') : 'none'),
      clickableIcons: $(this).data('clickable-icons'),
      disableDoubleClickZoom: !$(this).data('double-click-zoom'),
      draggable: $(this).data('draggable'),
      fullscreenControl: $(this).data('fullscreen-control'),
      gestureHandling: ($(this).data('gesture-handling') ? 'cooperative' : 'none'),
      keyboardShortcuts: $(this).data('keyboard-shortcuts'),
      mapTypeControl: $(this).data('map-type-control'),
      mapTypeId: google.maps.MapTypeId[$(this).data('map-type-id')],
      maxZoom: (isset($(this).data('max-zoom')) ? $(this).data('max-zoom') : null),
      minZoom: (isset($(this).data('min-zoom')) ? $(this).data('min-zoom') : null),
      rotateControl: $(this).data('rotate-control'),
      scaleControl: $(this).data('scale-control'),
      streetViewControl: $(this).data('street-view-control'),
      zoom: $(this).data('zoom'),
      zoomControl: $(this).data('zoom-control'),
    });

    var fitToBounds = $(this).data('fit-to-bounds');
    var latLngBounds = new google.maps.LatLngBounds();
    var mapMarkers = {};
    var mapMarkerInfoWindows = {};

    $(this).children('.marker-collection').children().each(function (i, e) {
      var markerId = $(this).attr('id');
      var coordinates = {
        lat: $(this).data('lat'),
        lng: $(this).data('lng')
      };

      if (fitToBounds === true) {
        latLngBounds.extend(coordinates);
      }

      var marker = new google.maps.Marker({
        map: map,
        position: coordinates
      });

      if (isset($(this).data('label'), 'string')) {
        marker.setLabel($(this).data('label'));
      }
      
      if (isset($(this).data('title'), 'string')) {
        marker.setTitle($(this).data('title'));
      }

      // If marker contents are not empty, add them to the info window
      if ($(this).children('.info-window-contents').text().trim().length > 0) {
        var infoWindow = new google.maps.InfoWindow({
          content: $(this).children('.info-window-contents').html(),
          maxWidth: 300,
        });

        marker.addListener('click', function() {
          infoWindow.open(map, marker);
        });

        mapMarkerInfoWindows[markerId+'-info'] = infoWindow;
      }

      mapMarkers[markerId] = marker;
    });

    var paddingTop = $(this).data('padding-top');
    var paddingLeft = $(this).data('padding-left');
    var paddingRight = $(this).data('padding-right');
    var paddingBottom = $(this).data('padding-bottom');

    var padding = {
      bottom: paddingBottom ? paddingBottom : 15,
      left: paddingLeft ? paddingLeft : 20,
      right: paddingRight ? paddingRight : 20,
      top: paddingTop ? paddingTop : 55,
    };

    if ($(this).hasClass('with-overlay') && window.innerWidth >= 768) {
      var overlay = $(this).children('.overlay-wrapper').children('.overlay').get(0);
      var boundary = overlay.getBoundingClientRect();

      if ($(this).hasClass('overlay-left')) {
        padding.left = padding.left + (boundary.left + window.scrollX + boundary.width);
      } else if ($(this).hasClass('overlay-right')) {
        padding.right = padding.right + (window.innerWidth - (boundary.left + window.scrollX + boundary.width));
      }
    }

    var initZoom = $(this).data('zoom');
    var maxZoom = $(this).data('max-zoom') || 15;
    var threshold = Math.max(initZoom, maxZoom, 15);
    google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
      if (map.getZoom() > threshold) {
        map.setZoom(threshold);
      }
    });

    if (!latLngBounds.isEmpty()) {
      if (padding !== false) {
        map.fitBounds(latLngBounds, padding);
      } else {
        map.fitBounds(latLngBounds);
      }
    } else {
      offsetCenter(map, padding.left, padding.top);
      offsetCenter(map, -padding.right, -padding.bottom);
    }

    // close all info windows if the map itself is clicked
    map.addListener('click', function (event) {
      for (const id in mapMarkerInfoWindows) {
        if (mapMarkerInfoWindows.hasOwnProperty(id)) {
          const infoWindow = mapMarkerInfoWindows[id];
          infoWindow.close();
        }
      }
    });

    this.googleMap = map;

    // if the map has an overlay and multiple markers, initialize it as a slider
    if ($(this).hasClass('with-overlay') && Object.keys(mapMarkers).length >= 2) {
      var overlay = $(this).children('.overlay-wrapper').children('.overlay');

      var infoSlider = overlay.children('.inner').slick({
        accessibility: false,
        arrows: false,
        dots: false,
        draggable: false,
        fade: $(this).data('fade-slides'),
        infinite: false,
        initialSlide: 0,
        speed: 500,
        swipe: false,
        touchMove: false,
      });

      for (const key in mapMarkers) {
        if (mapMarkers.hasOwnProperty(key)) {
          const marker = mapMarkers[key];
          
          marker.addListener('click', function (event) {
            var slide = $(overlay).find('[data-marker="' + key + '"]');
            infoSlider.slick('slickGoTo', slide.data('index'));
          });
        }
      }

      this.googleMapOverlayInfoSlider = infoSlider
    } else if ($(this).hasClass('with-overlay')) {
      // Fake slider initialization to fade in single info slide
      $(this).find('.overlay-wrapper .overlay .info-slider').addClass('slick-initialized');
    }

    var loadingSpinner = $(this).children('.loading');
    google.maps.event.addListenerOnce(map, 'tilesloaded', function (event) {
      loadingSpinner.fadeOut(300, function () {
        loadingSpinner.remove();
      });
    });
  });
};

// 5s after page is loaded, check for google API presence, otherwise show error on all maps
$(function () {
  setTimeout(function () {
    if (typeof google === "undefined" || typeof google.maps === "undefined") {
      Neos.translate(function (data, status, error) {
        $('.google-map').html('<p class="error">' + data + '</p>');
      }, 'error.unrenderableNotice', 'Main', 'Obis.GoogleMap');
    }
  }, 5000);
});